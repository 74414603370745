import { useAuth0 } from '@auth0/auth0-react';
import { datadogRum } from '@datadog/browser-rum';
import { FunctionComponent } from 'react';
import { Route, Switch } from 'react-router-dom';
import { ToastProvider } from '../../providers';
import { Landing } from '../landing';
import { SupportPage } from '../support';
import { AuthenticatedRoutes, PublicRoute } from './components';
import RouteConstants from './RouteConstants';

const Routes: FunctionComponent = () => {
  const { user } = useAuth0();
  if (user) {
    datadogRum.setUser({
      id: user.sub,
      email: user.email,
      name: user.name,
    });
  }
  return (
    <>
      <Switch>
        <PublicRoute exact path='/' component={Landing} />
        <Route path={RouteConstants.support} component={SupportPage} />
        <AuthenticatedRoutes />
      </Switch>
      <ToastProvider />
    </>
  );
};

export default Routes;
