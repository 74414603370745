import { useAuth0 } from '@auth0/auth0-react';
import { Fragment, FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import HandIcon from '../../assets/icons/hand.svg?react';
import PlusIcon from '../../assets/icons/plus_raw.svg?react';
import { MainContentWrapper } from '../../features/layout/main';
import { Topbar } from '../../features/layout/topbar';
import RouteConstants from '../../features/router/RouteConstants';

const Home: FunctionComponent = () => {
  const { t } = useTranslation();
  const { user } = useAuth0();

  return (
    <Fragment>
      <Topbar />
      <MainContentWrapper childClassName='grid grid-cols-2 h-full px-12 py-14'>
        <div className='flex flex-col gap-4'>
          <div className='flex items-center gap-1'>
            <h1 className='text-lg text-black-100 font-bold capitalize'>{t('users:info.welcomeBackUser', { userName: user.given_name })}</h1>
            <HandIcon className='size-5' />
          </div>
          <div className='*:text-gray-700 *:text-sm pb-5'>
            <h3 className='capitalize-first'>{t('home:noDataMessage')}</h3>
            <h3 className='capitalize-first'>{t('home:oneYouStartACampaign')}</h3>
          </div>
          <Link to={RouteConstants.campaigns} className='rounded-md bg-blue-700 shadow-sm p-2 w-fit'>
            <p className='lowercase capitalize-first text-sm text-white-100 font-medium'>{t('home:startYourFirstCampaign')}</p>
          </Link>
        </div>
        <div className='relative h-[400px]'>
          <PlusIcon className='absolute size-[160px] text-blue-700 bottom-3' />
          <PlusIcon className='absolute size-[320px] text-blue-500 bottom-0 left-[135px]' />
          <PlusIcon className='absolute size-[55px] text-gray-200 bottom-[15px] left-[440px]' />
          <PlusIcon className='absolute size-[120px] text-blue-500 bottom-[220px] left-[390px]' />
          <PlusIcon className='absolute size-[70px] text-gray-200 bottom-[300px] left-[160px]' />
        </div>
      </MainContentWrapper>
    </Fragment>
  );
};

export default Home;
