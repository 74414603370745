import { Fragment, FunctionComponent, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import AudiencesIcon from '../../assets/icons/audiences.svg?react';
import DataSourcesIcon from '../../assets/icons/data-sources.svg?react';
import DestinationsIcon from '../../assets/icons/destinations.svg?react';
import HomeIcon from '../../assets/icons/home.svg?react';
import MegaphoneIcon from '../../assets/icons/megaphone.svg?react';
import SettingsIcon from '../../assets/icons/settings.svg?react';
import { Loading } from '../../components/loaders';
import { Home } from '../../containers/home';
import { DataSourcesRouter } from '../../features/dataSources';
import { NavigationItem } from '../../models/navigation';
import { WebSocketChatAdapterProvider } from '../../providers/WebSocketChatAdapterHook';
import { useAppSelector } from '../../reducers';
import { AudiencesRouter } from '../audiences';
import { Campaigns } from '../campaigns';
import CompanyCreation from '../company/components/CompanyCreation';
import CyclrAuthRedirect from '../cyclr/CyclrAuthRedirect';
import { Destinations } from '../destinations';
import Logout from '../logout/Logout';
import { PrivateRoute } from '../router/components';
import RouteConstants, { SidebarRoutes } from '../router/RouteConstants';
import { Settings, SettingsRouter } from '../settings';
import { useUserInfo } from '../userInfo/hooks';
import WorkspaceCreation from '../workspaceEdit/WorkspaceCreation';
import { Footer } from './footer';
import { CollapsibleSidebar, SidebarContent } from './sidebar';
import { SupportPage } from '../support';

const SidebarWrapper: FunctionComponent = () => {
  const location = useLocation();
  const currentWorkspace = useAppSelector((r) => r.workspaces.currentWorkspace);

  const DEFAULT_NAV_ITEMS = [
    { name: 'Home', to: RouteConstants.home, icon: () => <HomeIcon className={'mr-3'} />, current: true },
    { name: 'Campaigns', to: RouteConstants.campaigns, icon: () => <MegaphoneIcon className={'mr-3 ms-0.5 text-gray-300 size-5'} />, current: false },
    {
      name: 'Data sources',
      to: RouteConstants.dataSources,
      icon: () => <DataSourcesIcon className={'mr-3'} />,
      current: false,
    },
    { name: 'Audiences', to: RouteConstants.audiences, icon: () => <AudiencesIcon className={'mr-3'} />, current: false },
    {
      name: 'Destinations',
      to: RouteConstants.destinations,
      icon: () => <DestinationsIcon className={'mr-3'} />,
      current: false,
    },
    // { name: 'Reports', to: RouteConstants.reports, icon: ChartBarIcon, current: false },
    // { name: 'Components', to: RouteConstants.componentShowcase, icon: PuzzleIcon, current: false },
    { name: 'Settings', to: `${RouteConstants.settings}/workspace/${currentWorkspace?.id}`, icon: () => <SettingsIcon className={'mr-3'} />, current: false },
  ];
  const [items, setItems] = useState<NavigationItem[]>(DEFAULT_NAV_ITEMS);

  useEffect(() => {
    setItems(items.map((currentItem) => ({ ...currentItem, current: location.pathname.startsWith(currentItem.to) })));
  }, [location]);

  const onItemClick = (item: NavigationItem) => {
    setItems(items.map((currentItem) => ({ ...currentItem, current: currentItem.name === item.name })));
  };

  return (
    <Fragment>
      <CollapsibleSidebar items={items} onItemClick={onItemClick} />
      {/* Static sidebar for desktop */}
      <div className='hidden bg-blue-650 md:flex md:flex-shrink-0'>
        <div className='flex flex-col w-64'>
          <div className='flex flex-col flex-grow pt-5 pb-4'>
            <SidebarContent items={items} onItemClick={onItemClick} />
          </div>
        </div>
      </div>
    </Fragment>
  );
};

const Layout: FunctionComponent = () => {
  const userInfo = useUserInfo();

  return (
    <div className='h-screen flex overflow-hidden bg-gray-100' data-testid='layout'>
      <PrivateRoute path={RouteConstants.logout} component={Logout} />
      <PrivateRoute path={SidebarRoutes} component={SidebarWrapper} />
      <PrivateRoute path={RouteConstants.support} component={SupportPage} />
      {!userInfo.onboard ? (
        <Loading />
      ) : userInfo.onboard.forceWorkspaceCreation ? (
        <div className='flex flex-col w-0 flex-1 overflow-hidden'>
          <PrivateRoute path={RouteConstants.home} component={Home} />
          <PrivateRoute path={RouteConstants.campaigns} component={Campaigns} />
          <PrivateRoute path={RouteConstants.createCompany} component={CompanyCreation} />
          <PrivateRoute path={RouteConstants.createWorkspace} component={WorkspaceCreation} />
          <PrivateRoute path={RouteConstants.settings} component={Settings} />
          <PrivateRoute path={SidebarRoutes} component={Footer} />
        </div>
      ) : (
        <WebSocketChatAdapterProvider>
          <div className='flex flex-col w-0 flex-1 overflow-hidden'>
            <PrivateRoute path={RouteConstants.home} component={Home} />
            <PrivateRoute path={RouteConstants.campaigns} component={Campaigns} />
            <PrivateRoute path={RouteConstants.createCompany} component={CompanyCreation} />
            <PrivateRoute path={RouteConstants.createWorkspace} component={WorkspaceCreation} />
            <PrivateRoute path={RouteConstants.dataSources} component={DataSourcesRouter} />
            <PrivateRoute path={RouteConstants.audiences} component={AudiencesRouter} />
            {/* <PrivateRoute path={RouteConstants.componentShowcase} component={ComponentsList} /> */}
            <PrivateRoute path={RouteConstants.destinations} component={Destinations} />
            <PrivateRoute path={RouteConstants.settings} component={SettingsRouter} />
            <PrivateRoute path={RouteConstants.cyclrAuthRedirect} component={CyclrAuthRedirect} />
            <PrivateRoute path={SidebarRoutes} component={Footer} />
          </div>
        </WebSocketChatAdapterProvider>
      )}
    </div>
  );
};

export default Layout;
