import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { NavigationItem } from '../../../models/navigation';
import { RootState } from '../../../reducers';
import RouteConstants from '../../router/RouteConstants';
import { SidebarLink } from './';

interface ISidebarContentProps {
  items: NavigationItem[];
  onItemClick?: (item: NavigationItem) => void;
}

const SidebarContent: FunctionComponent<ISidebarContentProps> = ({ items, onItemClick }) => {
  const { t } = useTranslation();

  const workspace = useSelector((state: RootState) => state.workspaces?.currentWorkspace);

  // const { data } = useGetWorkspacesQuery({
  //   limit: 50,
  //   offset: 0,
  // });

  const onLinkClicked = (item: NavigationItem) => {
    if (onItemClick) {
      onItemClick(item);
    }
  };

  // const workspaces = data?.items || (workspace ? [workspace] : []);

  return (
    <>
      <div className='flex items-center justify-between flex-shrink-0 px-4'>
        <div className='flex gap-2 items-center'>
          <img className='h-8 w-auto' src='/assets/sidebar/logo.svg' alt='logo' />
          <p className='capitalize text-white-100 font-medium'>{t('home:marketingPlatform')}</p>
        </div>
        {/*<span className="text-base font-medium text-white-100">BETA</span>*/}
      </div>
      <div className='mt-5 flex-1 h-0 flex flex-col justify-between'>
        <nav className='px-2 space-y-1'>
          {items.map((item) => (workspace || item.to === RouteConstants.home) && <SidebarLink key={item.name} item={item} onClick={onLinkClicked} />)}
        </nav>
        <Link to={RouteConstants.support} className='py-2 px-4 justify-center self-center'>
          <p className='lowercase capitalize-first text-sm text-gray-100'>{t('support:writeToSupport')}</p>
        </Link>
        {/* {workspaces.length > 0 ? (
          <div className='m-2 mt-auto'>
            <WorkspaceSelector workspaces={workspaces} />
          </div>
        ) : (
          <div className='m-2 mt-auto' />
        )} */}
      </div>
    </>
  );
};

export default SidebarContent;
