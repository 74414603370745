import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { RouteConstants } from '../../../router/RouteConstants';
import { PlatformSectionType } from './types';

type PlatformSectionProps = {
  title: PlatformSectionType;
  destination: keyof typeof RouteConstants;
};

export const PlatformSection: FC<PlatformSectionProps> = ({ title, destination }) => {
  const { t } = useTranslation();

  return (
    <article className='grid grid-rows-subgrid row-span-3 p-4 border-2 rounded-lg'>
      <p className='capitalize font-bold'>{t(`campaign:${title}`)}</p>
      <p className='capitalize-first text-gray-700'>{t(`campaign:${title}Section`)}</p>
      <div className='flex items-end justify-center'>
        <Link to={RouteConstants[destination]} className='rounded-md bg-blue-600 shadow-sm py-2 px-4'>
          <p className='lowercase capitalize-first text-sm text-white-100 font-medium'>{t('destinations:buttons.goToDestination', { destination })}</p>
        </Link>
      </div>
    </article>
  );
};
