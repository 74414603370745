import { useAuth0 } from '@auth0/auth0-react';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { MainContentWrapper } from '../layout/main';
import { Topbar } from '../layout/topbar';
import { PlatformSection } from './parts/PlatformSection';

export const Campaigns: FC = () => {
  const { user } = useAuth0();
  const { t } = useTranslation();

  return (
    <>
      <Topbar />
      <MainContentWrapper childClassName='h-full flex flex-col pb-[92px] pt-[30px] ps-[74px] gap-[30px]'>
        <p className='capitalize-first font-bold text-2xl'>{t('campaign:yourFirstCampaign', { userName: user.given_name })}</p>
        <div className='flex justify-center items-center grow'>
          <div className='grid self-center grid-cols-[repeat(3,256px)] grid-rows-[repeat(2,min-content),1fr] gap-x-8 relative h-[555px]'>
            <PlatformSection title='import' destination='dataSources' />
            <PlatformSection title='organize' destination='audiences' />
            <PlatformSection title='export' destination='destinations' />
            <img src='/assets/image/campaign_schema.svg' alt='campaign_schema' className='absolute left-[20px] top-[20%]' />
          </div>
        </div>
      </MainContentWrapper>
    </>
  );
};
