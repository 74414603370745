import { Audience } from '../../../models/audiences';
import { audiencerateApi } from '../../audiencerate';
import { SupportEmailRequest } from './types';

export const supportApi = audiencerateApi.injectEndpoints({
  endpoints: (build) => ({
    sendSupportEmail: build.mutation<Audience, SupportEmailRequest>({
      query: (body) => ({
        url: `/v1/support`,
        method: 'POST',
        body,
      }),
    }),
  }),
  overrideExisting: false,
});

export const { useSendSupportEmailMutation } = supportApi;
