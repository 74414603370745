import React, { FunctionComponent } from 'react';
import { twMerge } from 'tailwind-merge';
import { concatClassNames } from '../../utils';

interface IInputProps {
  id?: string;
  label?: string;
  className?: string;
  error?: string;
  onChange?: (value: React.ChangeEvent<HTMLTextAreaElement>) => void;
  icon?: (props: React.SVGProps<SVGSVGElement>) => JSX.Element;
  wrapperClassName?: string;
}

type TInputType = IInputProps & React.ComponentProps<'textarea'>;

const TextArea: FunctionComponent<TInputType> = (props) => {
  const { id, name, label, className, error, onChange, wrapperClassName, ...rest } = props;

  let inputStyle = 'block w-full px-4 sm:text-sm border border-gray-300 focus:border-blue-600 focus:border focus:outline-none rounded-md';

  if (props.icon) {
    inputStyle = concatClassNames(inputStyle, 'pl-10');
  }

  if (error) {
    inputStyle = concatClassNames(inputStyle, 'border-red-500 hover:border-red-500 focus:border-red-500');
  }

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    if (onChange) {
      onChange(e);
    }
  };

  return (
    <div className={twMerge('my-4', wrapperClassName)}>
      {label && (
        <label htmlFor={name} className='block text-sm font-medium text-gray-700 py-2'>
          {label}
        </label>
      )}
      <div className='mt-1 relative rounded-md w-auto'>
        {props.icon && (
          <div className='absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none'>
            <props.icon className='mr-4 flex-shrink-0 h-6 w-6 text-gray-500' />
          </div>
        )}
        <textarea id={id} name={name} onChange={handleChange} className={className ? concatClassNames(inputStyle, className) : inputStyle} {...rest} />
      </div>
      {error && <p className='mt-2 text-sm text-red-600'>{error}</p>}
    </div>
  );
};

export default TextArea;
