import React, { FunctionComponent, PropsWithChildren } from 'react';
import { concatClassNames } from '../../../utils';
import { twMerge } from 'tailwind-merge';

interface IMainContentWrapperProps {
    className?: string;
    childClassName?: string;
}

const MainContentWrapper: FunctionComponent<PropsWithChildren<IMainContentWrapperProps>> = ({ children, className, childClassName }) => (
  <main className={concatClassNames('flex-1 relative overflow-y-auto focus:outline-none', className || '')}>
    <div className={twMerge('py-6 px-6 h-auto', childClassName)}>{children}</div>
  </main>
);

export default MainContentWrapper;
