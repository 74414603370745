import getYear from 'date-fns/getYear';
import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import ChatFloatingActionButton from '../../../components/buttons/ChatFloatingButtonAction';
import { default as config } from '../../../config';
import { commit, version } from '../../../version.json';

const Footer: FunctionComponent = () => {
  const { t } = useTranslation();

  const chatEnabled = import.meta.env.VITE_FEATURES_ENABLED?.includes('chat');

  return (
    <footer className='bg-white-100'>
      {chatEnabled && <ChatFloatingActionButton />}
      <div className='py-4 px-4 sm:px-6 md:flex md:items-center justify-end lg:px-8'>
        <div className='mt-4 md:mt-0 md:order-1 hidden md:flex md:flex-shrink-0 justify-between w-full text-center text-sm text-gray-400'>
          {config.env !== 'prod' && (
            <div className='flex gap-2'>
              <p className='capitalize-first'>{t('build:versionNumber', { version: version })}</p>
              <p className='capitalize-first'>{t('build:buildNumber', { build: commit })}</p>
            </div>
          )}
          {/* <div className=''>&copy; {getYear(new Date())} CDP - Audiencerate Ltd. All rights reserved.</div> */}
          <p>{t('layout:platformName')}</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
