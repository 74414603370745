import { useFormik } from 'formik';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import * as Yup from 'yup';
import { Button } from '../../components/buttons';
import { Input, TextArea } from '../../components/inputs';
import { RootState } from '../../reducers';
import { useSendSupportEmailMutation } from '../../services/endpoints/support/endpoints';

export const SupportPage: FC = () => {
  const { t } = useTranslation();
  const workspace = useSelector((state: RootState) => state.workspaces.currentWorkspace);

  const [sendEmailToSupport] = useSendSupportEmailMutation();

  const { values, errors, handleSubmit, handleChange } = useFormik({
    initialValues: {
      email: '',
      message: '',
    },
    validationSchema: Yup.object({
      email: Yup.string().email('Invalid email').required('Required'),
      message: Yup.string().min(5, 'Too Short!').max(500, 'Too Long!').required('Required'),
    }),
    onSubmit: async (values) => {
      await sendEmailToSupport({
        ...values,
        workspaceId: workspace?.id,
      });
    },
    validateOnBlur: false,
    validateOnChange: false,
  });

  return (
    <div className='bg-wave h-screen w-screen flex flex-col justify-center items-center'>
      <div className='flex flex-col items-center'>
        <img className='w-40' src='ar_logo.png' alt='logo' />
      </div>
      <form onSubmit={handleSubmit} className='flex flex-col gap-[23px] bg-white-100 rounded-lg shadow-md p-8 pb-[26px] w-[426px]'>
        <Input
          id='email'
          type='email'
          value={values.email}
          onChange={handleChange}
          error={errors.email}
          className='placeholder-shown:capitalize'
          placeholder={t('support:yourEmail')}
        />
        <TextArea
          id='message'
          value={values.message}
          onChange={handleChange}
          error={errors.message}
          className='min-h-[58px] max-h-96'
          wrapperClassName='m-0'
          placeholder={t('support:writeToTheSupportPlaceholder')}
          maxLength={500}
        />
        <Button className='capitalize rounded-md text-sm' type='submit'>
          {t('support:send')}
        </Button>
      </form>
    </div>
  );
};
